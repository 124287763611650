@import './src/sass/utils/variables';

.loading-container-wrapper {
  width: 100vw;
  height: 100%;
  z-index: 9999;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

.loading-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
